import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Push from "../components/push"
import EasySection from "../components/easy_section"
import ChromeSection from "../components/chrome_section"
import DomainSection from "../components/domain_section"
import UsersSection from "../components/users_section"
import DashboardSection from "../components/dashboard_section"
import DetailsSection from "../components/details_section"
import CallToActionSection from "../components/call_to_action_section"
import FaqSection from "../components/faq_section"

class Faq extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Terms of Service" slug="/terms/" />
        <div className="container terms">
          <h2>Hyperlink Terms of Service</h2>

          <p>Effective: September 17, 2019</p>

          <p>
            By using Hyperlink or any of our other products or services that link to these Terms (we refer to these simply
            as
            the
            “Services”), you agree to the Terms. Of course, if you don’t agree with them, then don’t use the Services.
    </p>

          <p>
            ARBITRATION NOTICE: THESE TERMS CONTAIN AN ARBITRATION CLAUSE A LITTLE LATER ON. EXCEPT FOR CERTAIN TYPES OF
            DISPUTES
            MENTIONED IN THAT ARBITRATION CLAUSE, YOU AND HYPERLINK AGREE THAT DISPUTES BETWEEN US WILL BE RESOLVED BY
            MANDATORY
            BINDING ARBITRATION, AND YOU AND HYPERLINK WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT OR
            CLASS-WIDE
            ARBITRATION.
    </p>

          <h3>1. Who Can Use the Services</h3>
          <p>No one under 13 is allowed to create an account or use the Services. We may offer additional Services with
            additional
      terms that may require you to be even older to use them. So please read all terms carefully.</p>

          <p>By using the Services, you state that:</p>

          <ul>
            <li>You can form a binding contract with Hyperlink</li>
            <li>You are not a person who is barred from receiving the Services under the laws of the United States or any
              other
              applicable jurisdiction—including, for example, that you do not appear on the U.S. Treasury Department’s list of
        Specially Designated Nationals or face any other similar prohibition.</li>
            <li>You are not a convicted sex offender.</li>
            <li>You will comply with these Terms and all applicable local, state, national, and international laws, rules, and
        regulations.</li>
          </ul>

          <p>
            If you are using the Services on behalf of a business or some other entity, you state that you are authorized to
            grant
            all licenses set forth in these Terms and to agree to these Terms on behalf of the business or entity.
    </p>

          <h3>2. Rights We Grant You</h3>
          <p>
            Hyperlink grants you a personal, worldwide, royalty-free, non-assignable, nonexclusive, revocable, and
            non-sublicensable license to access and use the Services. This license is for the sole purpose of letting you use
            and
            enjoy the Services’ benefits in a way that these Terms and our usage policies, such as our Community Guidelines,
            allow.
    </p>

          <p>
            Any software that we provide you may automatically download and install upgrades, updates, or other new features.
            You
            may be able to adjust these automatic downloads through your device’s settings.
    </p>

          <p>
            You may not copy, modify, distribute, sell, or lease any part of our Services, nor may you reverse engineer or
            attempt
            to extract the source code of that software, unless laws prohibit these restrictions or you have our written
            permission to do so.
    </p>

          <h3>3. Rights You Grant Us</h3>
          <p>
            Many of our Services let you create, upload, post, send, receive, and store content. When you do that, you retain
            whatever ownership rights in that content you had to begin with. But you grant us a license to use that content.
    </p>

          <p>
            For any content you submit to the Services, you grant Hyperlink and our affiliates a worldwide, royalty-free,
            sublicensable, and transferable license to host,
            store, use, display, reproduce, modify, adapt, edit, publish, and distribute that content. In addition, you also
            grant us
            a perpetual license to create derivative works from, promote, exhibit, broadcast, syndicate, sublicense, publicly
            perform, and publicly display this content in any form and in any and all media or distribution methods (now known
            or later developed). To the extent it’s necessary, when you appear in, create, upload, post, or send content,
            you also grant Hyperlink, our affiliates, and our business partners the unrestricted, worldwide, perpetual
            right
            and
            license to use your name, likeness, and voice, including in connection with commercial or sponsored content. This
            means, among other things, that you will not be entitled to any compensation from Hyperlink, our affiliates, or
            our
            business partners if your name, likeness, or voice is conveyed through the Services, either on the Hyperlink
            application or on one of our business partner’s platforms.
    </p>

          <p>
            While we’re not required to do so, we may access, review, screen, and delete your content at any time and for any
            reason, including to provide and develop the Services or if we think your content violates these Terms. You alone,
            though, remain responsible for the content you create, upload, post, send, or store through the Service.
    </p>

          <p>
            The Services may contain advertisements. In consideration for Hyperlink letting you access and use the
            Services,
            you
            agree that we, our affiliates, and our third-party partners may place advertising on the Services. Because the
            Services contain content that you and other users provide us, advertising may sometimes appear near your content.
    </p>

          <p>
            We always love to hear from our users. But if you provide feedback or suggestions, just know that we can use them
            without compensating you, and without any restriction or obligation to you.
    </p>

          <h3>4. The Content of Others</h3>
          <p>
            Much of the content on our Services is produced by users, publishers, and other third parties. Whether that
            content
            is
            posted publicly or sent privately, the content is the sole responsibility of the person or organization that
            submitted
            it. Although Hyperlink reserves the right to review or remove all content that appears on the Services, we do
            not
            necessarily review all of it. So we cannot—and do not—take responsibility for any content that others provide
            through
            the Services.
    </p>

          <p>
            Through these Terms, we make clear that we do not want the Services put to bad uses. But
            because we do not review all content, we cannot guarantee that content on the Services will always conform to our
            Terms.
    </p>

          <h3>5. Privacy</h3>
          <p>
            Your privacy matters to us. You can learn how we handle your information when you use our Services by reading our
            Privacy Policy. We encourage you to give the Privacy Policy a careful look because, by using our Services, you
            agree
            that Hyperlink can collect, use, and share your information consistent with that policy.
    </p>

          <h3>6. Respecting Other People’s Rights</h3>
          <p>
            Hyperlink respects the rights of others. And so should you. You therefore may not use the Services, or enable
            anyone
            else to use the Services, in a manner that:
    </p>

          <ul>
            <li>violates or infringes someone else’s rights of publicity, privacy, copyright, trademark, or other
        intellectual-property right.</li>
            <li>bullies, harasses, or intimidates.</li>
            <li>defames.</li>
            <li>spams or solicits our users.</li>
          </ul>
          <p>
            You must also respect Hyperlink’s rights and adhere to any brand guidelines published
            by Hyperlink You may not do any of the following (or enable anyone else to do so):
    </p>

          <ul>
            <li>use branding, logos, designs, photographs, videos, or any other materials used in our Services, except as
              explicitly
        allowed by the Brand Guidelines or other brand guidelines published by Hyperlink</li>
            <li>copy, archive, download, upload, distribute, syndicate, broadcast, perform, display, make available, or
              otherwise use
        any portion of the Services or the content on the Services except as set forth in these Terms.</li>
            <li>use the Services, any tools provided by the Services, or any content on the Services for any commercial
              purposes
        without our consent.</li>
          </ul>
          <p>
            In short: You may not use the Services or the content on the Services in ways that are not authorized by these
            Terms.
            Nor may you help anyone else in doing so.
    </p>

          <h3>7. Respecting Copyright</h3>
          <p>
            Hyperlink honors copyright laws, including the Digital Millennium Copyright Act. We therefore take reasonable
            steps to
            expeditiously remove from our Services any infringing material that we become aware of. And if Hyperlink
            becomes
            aware
            that one of its users has repeatedly infringed copyrights, we will take reasonable steps within our power to
            terminate
            the user’s account.
    </p>

          <p>
            If you believe that anything on the Services
            infringes a copyright that you own or control, please file a notice with copyright@playrml.com
    </p>

          <p>
            Don’t use this email address for anything other than reporting copyright infringement, as such emails will be
            ignored.
    </p>

          <p>
            If you file a notice with our Copyright Agent, it must comply with the requirements set forth at 17 U.S.C. §
            512(c)(3). That means the notice must:
    </p>

          <ul>
            <li>contain the physical or electronic signature of a person authorized to act on behalf of the copyright owner.
      </li>
            <li>identify the copyrighted work claimed to have been infringed.</li>
            <li>identify the material that is claimed to be infringing or to be the subject of infringing activity and that is
              to be
              removed, or access to which is to be disabled, and information reasonably sufficient to let us locate the
        material.</li>
            <li>provide your contact information, including your address, telephone number, and an email address.</li>
            <li>provide a personal statement that you have a good-faith belief that the use of the material in the manner
              complained
        of is not authorized by the copyright owner, its agent, or the law.</li>
            <li>provide a statement that the information in the notification is accurate and, under penalty of perjury, that
              you
              are
        authorized to act on behalf of the copyright owner.</li>
          </ul>
          <h3>8. Safety</h3>
          <p>
            We try hard to keep our Services a safe place for all users. But we can’t guarantee it. That’s where you come in.
            By
      using the Services, you agree that:</p>

          <ul>
            <li>You will not use the Services for any purpose that is illegal or prohibited in these Terms.</li>
            <li>You will not use any robot, spider, crawler, scraper, or other automated means or interface to access the Services
            or
      extract other user’s information.</li>
            <li>You will not use or develop any third-party applications that interact with the Services or other users’
              content
              or
        information without our written consent.</li>
            <li>You will not use the Services in a way that could interfere with, disrupt, negatively affect, or inhibit other
              users
              from fully enjoying the Services, or that could damage, disable, overburden, or impair the functioning of the
        Services.</li>
            <li>You will not use or attempt to use another user’s account, username, or password without their permission.
      </li>
            <li>You will not solicit login credentials from another user.</li>
            <li>You will not post content that contains or links to pornography, graphic violence, threats, hate speech, or
        incitements to violence.</li>
            <li>You will not upload viruses or other malicious code or otherwise compromise the security of the Services.</li>
            <li>You will not attempt to circumvent any content-filtering techniques we employ, or attempt to access areas or
              features
        of the Services that you are not authorized to access.</li>
            <li>You will not probe, scan, or test the vulnerability of our Services or any system or network.</li>
            <li>You will not encourage or promote any activity that violates these Terms.</li>
          </ul>
          <p>We also care about your safety while using our Services. So do not use our Services in a way that would distract
            you
            from obeying traffic or safety laws. For example, never Hyperlink and drive. And never put yourself or others
            in harm’s
            way
      just to capture Hyperlink content.</p>

          <h3>9. Your Account</h3>
          <p>
            You are responsible for any activity that occurs in your Hyperlink account. So it’s important that you keep
            your
            account secure. One way to do that is to select a strong password that you don’t use for any other account.
    </p>

          <p>By using the Services, you agree that, in addition to exercising common sense:</p>

          <ul>
            <li>You will not create more than one account for yourself.</li>
            <li>You will not create another account if we have already disabled your account, unless you have our written
              permission
        to do so.</li>
            <li>You will not buy, sell, rent, or lease access to your Hyperlink account, content, a Hyperlink username,
              or a friend
              link
        without our written permission.</li>
            <li>You will not share your password.</li>
            <li>You will not log in or attempt to access the Services through unauthorized third-party applications or
        clients.</li>
          </ul>
          <p>
            If you think that someone has gained access to your account, please immediately reach out to Hyperlink Support.
    </p>

          <h3>11. Data Charges and Mobile Phones</h3>
          <p>
            You are responsible for any mobile charges that you may incur for using our Services, including text-messaging and
            data charges. If you’re unsure what those charges may be, you should ask your service provider before using the
            Services.
    </p>

          <p>
            If you change or deactivate the mobile phone number that you used to create a Hyperlink account, you must
            update
            your
            account information through Settings within 72 hours to prevent us from sending to someone else messages intended
            for
            you.
    </p>

          <h3>12. Third-Party Services</h3>
          <p>
            If you use a service, feature, or functionality that is operated by a third party and made available through our
            Services (including Services we jointly offer with the third party), each party’s terms will govern the respective
            party’s relationship with you. Hyperlink is not responsible or liable for a third party’s terms or actions
            taken
            under
            the third party’s terms.
    </p>

          <h3>13. Modifying the Services and Termination</h3>

          <p>
            We’re relentlessly improving our Services and creating new ones all the time. That means we may add or remove
            features, products, or functionalities, and we may also suspend or stop the Services altogether. We may take any
            of
            these actions at any time, and when we do, we may not provide you with any notice beforehand.
    </p>

          <p>
            Hyperlink terminate these Terms with you at any time, for any reason, and without advanced notice. That
            means
            that we may stop providing you with any Services, or impose new or additional limits on your ability to use our
            Services. For example, we may deactivate your account due to prolonged inactivity, and we may reclaim your
            username
            at
            any time for any reason.
    </p>

          <p>
            Regardless of who terminates these Terms, both you and Hyperlink continue to be bound by Sections 3, 6, 9, 10,
            and
            13-22 of the Terms.
    </p>

          <h3>14. Indemnity</h3>
          <p>
            You agree, to the extent permitted by law, to indemnify, defend, and hold harmless Hyperlink, our affiliates,
            directors, officers, stockholders, employees, licensors, and agents from and against any and all complaints,
            charges,
            claims, damages, losses, costs, liabilities, and expenses (including attorneys’ fees) due to, arising out of, or
            relating in any way to: (a) your access to or use of the Services; (b) your content; and (c) your breach of these
            Terms.
    </p>

          <h3>15. Disclaimers</h3>
          <p>
            We try to keep the Services up and running and free of annoyances. But we make no promises that we will succeed.
    </p>

          <p>
            THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” AND TO THE EXTENT PERMITTED BY LAW WITHOUT WARRANTIES OF ANY
            KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
            A
            PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. IN ADDITION, WHILE HYPERLINK ATTEMPTS TO PROVIDE A GOOD USER
            EXPERIENCE, WE DO NOT REPRESENT OR WARRANT THAT: (A) THE SERVICES WILL ALWAYS BE SECURE, ERROR-FREE, OR TIMELY;
            (B)
            THE SERVICES WILL ALWAYS FUNCTION WITHOUT DELAYS, DISRUPTIONS, OR IMPERFECTIONS; OR (C) THAT ANY CONTENT, USER
            CONTENT, OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICES WILL BE TIMELY OR ACCURATE.
    </p>

          <p>
            HYPERLINK TAKES NO RESPONSIBILITY AND ASSUMES NO LIABILITY FOR ANY CONTENT THAT YOU, ANOTHER USER, OR A THIRD
            PARTY
            CREATES, UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR THROUGH OUR SERVICES. YOU UNDERSTAND AND AGREE THAT YOU
            MAY
            BE EXPOSED TO CONTENT THAT MIGHT BE OFFENSIVE, ILLEGAL, MISLEADING, OR OTHERWISE INAPPROPRIATE, NONE OF WHICH HYPERLINK WILL BE RESPONSIBLE FOR.
    </p>

          <h3>16. Limitation of Liability</h3>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, HYPERLINK AND OUR MANAGING MEMBERS, SHAREHOLDERS, EMPLOYEES,
            AFFILIATES,
            LICENSORS, AGENTS, AND SUPPLIERS WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
            PUNITIVE,
            OR
            MULTIPLE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF
            DATA,
            USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS
            OR
            USE
            THE SERVICES; (B) THE CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON OR THROUGH THE SERVICES; OR (C)
            UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN IF HYPERLINK HAS BEEN ADVISED OF THE POSSIBILITY
            OF
            SUCH
            DAMAGES. IN NO EVENT WILL HYPERLINK'S AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES EXCEED THE
            GREATER
            OF $100 USD OR THE AMOUNT YOU PAID HYPERLINK, IF ANY, IN THE LAST 12 MONTHS.
    </p>

          <h3>17. Arbitration, Class-Action Waiver, and Jury Waiver</h3>
          <p>
            PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY BECAUSE THEY REQUIRE YOU AND HYPERLINK TO AGREE TO RESOLVE ALL
            DISPUTES
            BETWEEN US THROUGH BINDING INDIVIDUAL ARBITRATION.
    </p>

          <p>
            Applicability of Arbitration Agreement. You and Hyperlink agree that all claims and disputes (whether contract,
            tort,
            or otherwise), including all statutory claims and disputes, arising out of or relating to these Terms or the use
            of
            the Services that cannot be resolved in small claims court will be resolved by binding arbitration on an
            individual
            basis, except that you and Hyperlink are not required to arbitrate any dispute in which either party seeks
            equitable
            relief for the alleged unlawful use of copyrights, trademarks, trade names, logos, trade secrets, or patents. To
            be
            clear: The phrase “all claims and disputes” also includes claims and disputes that arose between us before the
            effective date of these Terms.
    </p>
          <p>
            Arbitration Rules. The Federal Arbitration Act governs the interpretation and enforcement of this
            dispute-resolution
            provision. Arbitration will be initiated through the American Arbitration Association (“AAA”) and will be
            governed
            by
            the AAA Consumer Arbitration Rules, available by calling the AAA at
            1-800-778-7879. If the AAA is not available to arbitrate, the parties will select an alternative arbitral forum.
            The
            rules of the arbitral forum will govern all aspects of this arbitration, except to the extent those rules
            conflict
            with these Terms. The arbitration will be conducted by a single neutral arbitrator. Any claims or disputes where
            the
            total amount sought is less than $10,000 USD may be resolved through binding non-appearance-based arbitration,
            at
            the
            option of the party seeking relief. For claims or disputes where the total amount sought is $10,000 USD or more,
            the
            right to a hearing will be determined by the arbitral forum’s rules. Any judgment on the award rendered by the
            arbitrator may be entered in any court of competent jurisdiction.
            Additional Rules for Non-appearance Arbitration. If non-appearance arbitration is elected, the arbitration will
            be
            conducted by telephone, online, written submissions, or any combination of the three; the specific manner will
            be
            chosen by the party initiating the arbitration. The arbitration will not involve any personal appearance by the
            parties or witnesses unless the parties mutually agree otherwise.
    </p>
          <p>
            Fees. If you choose to arbitrate with Hyperlink, you will not have to pay any fees to do so. That is because
            Hyperlink
            will reimburse you for your filing fee and the AAA’s Consumer Arbitration Rules provide that any hearing fees
            and
            arbitrator compensation are our responsibility. To the extent another arbitral forum is selected, Hyperlink
            will
            pay
      that forum’s fees as well.</p>
          <p>
            Authority of the Arbitrator. The arbitrator will decide the jurisdiction of the arbitrator and the rights and
            liabilities, if any, of you and Hyperlink. The dispute will not be consolidated with any other matters or
            joined
            with
            any other cases or parties. The arbitrator will have the authority to grant motions dispositive of all or part
            of
            any
            claim or dispute. The arbitrator will have the authority to award monetary damages and to grant any non-monetary
            remedy or relief available to an individual under law, the arbitral forum’s rules, and the Terms. The arbitrator
            will
            issue a written award and statement of decision describing the essential findings and conclusions on which the
            award
            is based, including the calculation of any damages awarded. The arbitrator has the same authority to award
            relief
            on
            an individual basis that a judge in a court of law would have. The award of the arbitrator is final and binding
            upon
            you and Hyperlink.
    </p>
          <p>
            Waiver of Jury Trial. YOU AND HYPERLINK WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A
            TRIAL
            IN FRONT OF A JUDGE OR A JURY. You and Hyperlink are instead electing to have claims and disputes resolved by
            arbitration. Arbitration procedures are typically more limited, more efficient, and less costly than rules
            applicable
            in court and are subject to very limited review by a court. In any litigation between you and Hyperlink over
            whether
            to vacate or enforce an arbitration award, YOU AND Hyperlink WAIVE ALL RIGHTS TO A JURY TRIAL, and elect
            instead
            to
            have the dispute be resolved by a judge.
            Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT
            MUST
            BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR
            USER
            CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If, however,
            this
            waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor we are entitled to
            arbitration; instead all claims and disputes will be resolved in a court as set forth in Section 18.
            Right to Waive. Any rights and limitations set forth in this arbitration agreement may be waived by the party
            against
            whom the claim is asserted. Such waiver will not waive or affect any other portion of this arbitration
            agreement.
    </p>
          <p>
            Opt-out. You may opt out of this arbitration agreement. If you do so, neither you nor Hyperlink can force the
            other
            to
            arbitrate. To opt out, you must notify Hyperlink. in writing no later than 30 days after first becoming subject
            to
            this
            arbitration agreement. Your notice must include your name and address, your Hyperlink phone number and the
            email
            address
            you used to set up your Hyperlink account (if you have one), and an unequivocal statement that you want to opt
            out
            of
            this arbitration agreement. You must either mail your opt-out notice to this email address:
            arbitration-opt-out@playrml.com.
    </p>
          <p>
            Small Claims Court. Notwithstanding the foregoing, either you or Hyperlink may bring an individual action in
            small
            claims court.
    </p>
          <p>
            Arbitration Agreement Survival. This arbitration agreement will survive the termination of your relationship
            with
            Hyperlink
    </p>
          <h3>18. Exclusive Venue</h3>
          <p>
            To the extent that these Terms allow you or Hyperlink to initiate litigation in a court, both you and Hyperlink
            agree
            that all claims and disputes (whether contract, tort, or otherwise), including statutory claims and disputes,
            arising
            out of or relating to the Terms or the use of the Services will be litigated exclusively in the United States
            District
            Court for the Central District of California. If, however, that court would lack original jurisdiction over the
            litigation, then all such claims and disputes will be litigated exclusively in the Superior Court of California,
            County of San Francisco. You and Hyperlink consent to the personal jurisdiction of both courts.
    </p>

          <h3>19. Choice of Law</h3>
          <p>
            Except to the extent they are preempted by U.S. federal law, the laws of California, other than its
            conflict-of-laws
            principles, govern these Terms and any claims and disputes (whether contract, tort, or otherwise) arising out of
            or
            relating to these Terms or their subject matter.
    </p>

          <h3>20. Severability</h3>
          <p>
            If any provision of these Terms is found unenforceable, then that provision will be severed from these Terms and
            not
            affect the validity and enforceability of any remaining provisions.
    </p>

          <h3>21. Additional Terms for Specific Services</h3>
          <p>
            Given the breadth of our Services, we sometimes need to craft additional terms and conditions for specific
            Services.
            Those additional terms and conditions, which will be available with the relevant Services, then become part of
            your
            agreement with us if you use those Services. If any part of those additional terms and conditions conflicts with
            these
            Terms, the additional terms and conditions will prevail.
    </p>

          <h3>22. Final Terms</h3>
          <p>
            These Terms (together with any additional terms applicable to specific Services you use) make up the entire
            agreement
            between you and Hyperlink, and supersede any prior agreements.
            These Terms do not create or confer any third-party beneficiary rights.
            If we do not enforce a provision in these Terms, it will not be considered a waiver.
            We reserve all rights not expressly granted to you.
            You may not transfer any of your rights or obligations under these Terms without our consent.
    </p>
        </div>
      </Layout >
    )
  }
}

export default Faq

export const pageQuery = graphql`
  query {
          site {
          siteMetadata {
          title
        }
        }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC }) {
          edges {
          node {
          excerpt
          fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
      title
      description
    }
  }
}
}
}
`
